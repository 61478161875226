import Swiper, {Navigation} from 'swiper';
import {SwiperOptions} from 'swiper/types/swiper-options';
import {isMobile} from '@/utils/isMobile';
import {debounce} from '@/utils/debounce';

Swiper.use([Navigation]);

export default class FeaturedContentCardsController {
    private SWIPER_SELECTOR: string;
    private swiper!: Swiper;
    private swiperOptions: SwiperOptions;
    private featuredContentCardsEl: NodeListOf<HTMLElement>;

    constructor() {
        this.SWIPER_SELECTOR = '.card-list--featured';
        this.featuredContentCardsEl = document.querySelectorAll(this.SWIPER_SELECTOR);
        this.swiperOptions = {
            grabCursor: true,
            spaceBetween: 45,
            centeredSlides: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            on: {
                init: () => {
                    // Recalculate height of content and reposition arrows
                    this.resizeContentHeight();
                },
            },
        };
        if (this.featuredContentCardsEl.length !== 0) {
            this.addEventListeners();
            this.init();
        }
    }

    private resizeContentHeight() {
        for (const sw of this.featuredContentCardsEl) {
            const swiperButtons = sw.querySelectorAll('.swiper-button');
            const swiperHeight = sw.clientHeight;
            const contentPageSlideContent = sw.querySelectorAll('.card__content');
            let contentPageSlideHeight = 0;
            let contentPageSlideTitleHeight = 0;

            if (contentPageSlideContent) {
                contentPageSlideContent.forEach((contentPageSlide: any) => {
                    if (contentPageSlide instanceof HTMLElement) {
                        const contentPageSlideContentTitle = contentPageSlide.querySelector('.card__title');
                        contentPageSlide.style.height = 'auto';

                        const contentHeight = contentPageSlide.getBoundingClientRect().height;
                        contentPageSlideHeight = contentHeight > contentPageSlideHeight ?
                            contentHeight : contentPageSlideHeight;

                        if (contentPageSlideContentTitle instanceof HTMLElement) {
                            const titleHeight = contentPageSlideContentTitle?.getBoundingClientRect().height;
                            contentPageSlideTitleHeight = titleHeight > contentPageSlideTitleHeight ?
                                titleHeight : contentPageSlideTitleHeight;
                        }
                    }
                });

                contentPageSlideContent.forEach((contentPageSlide: any) => {
                    if (contentPageSlide instanceof HTMLElement) {
                        const contentPageSlideContentTitle = contentPageSlide.querySelector('.card__title');
                        if (contentPageSlideContentTitle instanceof HTMLElement) {
                            contentPageSlideContentTitle.style.height = contentPageSlideTitleHeight + 'px';
                        }
                        contentPageSlide.style.height = contentPageSlideHeight + 'px';
                    }
                });
            }

            if (swiperButtons) {
                swiperButtons.forEach((swiperButton: any) => {
                    if (swiperButton instanceof HTMLElement) {
                        swiperButton.style.top = ((swiperHeight - contentPageSlideHeight) / 2) + 'px';
                    }
                });
            }
        }
    }

    private addEventListeners() {
        const returnedWindowResizeHandler = debounce(() => {
            this.windowResizeHandler();
        }, 250, false);

        window.addEventListener('resize', () => {
            returnedWindowResizeHandler();
        });
    }

    private init() {
        if (isMobile) {
            this.initializeSwiper();
        }

        window.addEventListener('load', () => {
            this.resizeContentHeight();
        });
    }

    private initializeSwiper(): void {
        this.swiper = new Swiper(this.SWIPER_SELECTOR, this.swiperOptions);
    }

    private destroySwiper(): void {
        if (this.swiper !== undefined) {
            if (Array.isArray(this.swiper)) {
                this.swiper.forEach((i) => i.destroy(true, true));
            } else {
                this.swiper.destroy(true, true);
            }
        }
    }

    private windowResizeHandler(): void {
        this.resizeContentHeight();
        if (window.matchMedia('only screen and (max-width: 1024px)').matches) {
            this.initializeSwiper();
        } else {
            this.destroySwiper();
        }
    }
}
