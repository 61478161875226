export default class ProductListController {
    private productListEl: HTMLElement | null;
    private productListItemEls!: NodeListOf<HTMLElement>;
    private noResultsEl!: HTMLElement | null;

    constructor() {
        this.productListEl = document.querySelector('.card-list--product');
        if (!this.productListEl) {
            return;
        }
        this.productListItemEls = this.productListEl.querySelectorAll('.card--product');
        this.noResultsEl = document.querySelector('.product-list__no-results');
    }

    public getElement(): HTMLElement | null {
        return this.productListEl;
    }

    public getOffsetTop(): number {
        return this.productListEl?.offsetTop || 0;
    }

    public filterProducts(selectedFiltersList: string[]): void {
        let productsHidden = 0;
        let productsShowing = 0;

        // If selected filters list is empty show all products
        if (selectedFiltersList.length === 0) {
            this.reset();
            return;
        }

        // Match products with the selected filters list
        for (const productListItemEl of this.productListItemEls) {
            const productFilterCriteria = productListItemEl.dataset.filterCriteria;

            // If product filter criteria is empty hide the product
            if (!productFilterCriteria) {
                productListItemEl.classList.add('d-none');
                productsHidden += 1;
                continue;
            }

            const productFilterCriteriaList = this.convertDatasetToList(productFilterCriteria);

            // Check if all product filter criteria match with the selected filter.
            // Show the product if all criteria are matched
            // Hide the product if not all criteria are matched
            if (this.doesAllFiltersMatchWithProductFilterCriteria(productFilterCriteriaList, selectedFiltersList)) {
                productListItemEl.classList.remove('d-none');
                productsShowing += 1;
            } else {
                productListItemEl.classList.add('d-none');
                productsHidden += 1;
            }
        }

        // Show "no result" message if there are more than 1 product hidden and 0 showing products
        const hasNoResults = productsHidden !== 0 && productsShowing === 0;
        hasNoResults ? this.showNoResultMessage(true) : this.showNoResultMessage(false);
    }

    public reset(): void {
        this.showAllProductListItemEls();
        this.showNoResultMessage(false);
    }

    private showAllProductListItemEls(): void {
        for (const productListItemEl of this.productListItemEls) {
            productListItemEl.classList.remove('d-none');
        }
    }

    private doesAllFiltersMatchWithProductFilterCriteria(filterCriteria: string[], filters: string[]): boolean {
        let matchedFiltersCounter = 0;

        for (const filter of filters) {

            if (filterCriteria.includes(filter)) {
                matchedFiltersCounter++;
            }
        }

        return matchedFiltersCounter === filters.length;
    }

    private convertDatasetToList(dataset: string): string[] {
        if (dataset) {
            return dataset.split(' ');
        }

        return [];
    }

    private showNoResultMessage(isShow: boolean): void {
        isShow ? this.noResultsEl?.classList.remove('d-none') : this.noResultsEl?.classList.add('d-none');
    }
}
