export default class TablesController {
    private tableEl!: NodeListOf<HTMLElement>;

    constructor() {
        this.tableEl = document.querySelectorAll('main table');
        if (!this.tableEl) {
            return;
        } else {
            this.tableEl.forEach((tableEl: HTMLElement) => {
                // Wrap tables in wrapper to be able to scroll horizontally
                const wrapper = document.createElement('div');
                wrapper.classList.add('table-wrapper');

                // Remove unneeded attributes
                tableEl.removeAttribute('border');
                tableEl.removeAttribute('cellspacing');
                tableEl.removeAttribute('cellpadding');
                tableEl.removeAttribute('style');

                const parent = tableEl.parentNode;
                if (parent) {
                    parent.insertBefore(wrapper, tableEl);
                }
                wrapper.appendChild(tableEl);
            });
        }
    }
}
