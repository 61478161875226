import { render, staticRenderFns } from "./EmbeddedForm.vue?vue&type=template&id=63570a23&scoped=true&"
import script from "./EmbeddedForm.vue?vue&type=script&lang=ts&"
export * from "./EmbeddedForm.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63570a23",
  null
  
)

export default component.exports