// Calculates maximum height of a child element and uses this value as height for all child elements of the parent
import {Breakpoint, mqMinWidth} from '@/utils/mq';

export const equalElementHeights = (parent: string, child: string, minWidth: Breakpoint) => {
    const mq = mqMinWidth(minWidth);
    if (mq.matches) {
        const parentContainers = document.querySelectorAll(parent);

        if (parentContainers.length > 0) {
            for (const parentContainer of parentContainers) {
                const childElements = parentContainer.querySelectorAll(child);
                if (childElements.length > 0) {
                    window.addEventListener('load', () => {
                        let calculatedHeight: number = 0;

                        for (const childElement of childElements) {
                            if (childElement instanceof HTMLElement && childElement.offsetHeight > calculatedHeight) {
                                calculatedHeight = childElement.offsetHeight;
                            }
                        }

                        for (const childElement of childElements) {
                            if (childElement instanceof HTMLElement) {
                                childElement.style.minHeight = calculatedHeight + 'px';
                            }
                        }
                    });
                }
            }
        }
    }
};
