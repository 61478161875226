import Vue from 'vue';

export function translate(term: string, ...translationPropertyData: any[]): string {
    const translations = (window as any)._translations;
    let translation: string = '';
    if (translations && term in translations) {
        translation = translations[term];
    } else {
        translation = term;
    }

    if (translationPropertyData != null && translationPropertyData.length > 0) {
        translationPropertyData.forEach((property, key) => {
            translation = translation.replace(`{${key}}`, property);
        });
    }
    return translation;
}

Vue.filter('translate', translate);
