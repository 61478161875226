import * as ScrollMagic from 'scrollmagic';

export const AddToController = (cta1: NodeListOf<Element>, cta2: NodeListOf<Element>,
                                controller: ScrollMagic.Controller) => {
    if (cta2 && cta2.length > 0) {
        const triggerCTA2 = document.querySelector('[data-trigger-cta2]');
        new ScrollMagic.Scene({
            triggerElement: triggerCTA2, triggerHook: 1,
        })
        .on('enter leave', (e) => {
            // if secondary CTA is visible, trigger animation
            if (window.getComputedStyle(cta2[0]).display === 'block') {
                if (e.type === 'enter') {
                    cta1[0].classList.add('single');
                    if (cta2[0] instanceof HTMLElement) {
                        cta2[0].style.transform = 'translateY(0%)';
                    }
                } else {
                    cta1[0].classList.remove('single');
                    if (cta2[0] instanceof HTMLElement) {
                        cta2[0].style.transform = 'translateY(100%)';
                    }
                }
            }
        })
        .addTo(controller);
    }
};

export const PrimaryCallToActionListener = (cta1: NodeListOf<Element>) => {
    if (cta1 && cta1.length > 0) {
        for (const ctaEl of cta1) {
            const cta1buttons = ctaEl.querySelectorAll<HTMLElement>('.animated-btn');
            if (cta1buttons && cta1buttons.length > 0) {
                for (const cta1button of cta1buttons) {
                    cta1button.addEventListener('click', () => {
                        cta1button.classList.toggle('expanded');
                        ctaEl.classList.toggle('expanded');
                    });
                }
            }
        }
    }
};

