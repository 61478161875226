export const Preloader = (parentEl: HTMLElement) => {
    window.onload = () => {
        const ctasWrappers = document.querySelectorAll('.ctas-wrapper');
        if (ctasWrappers.length > 0) {
            setTimeout(() => {
                for (const ctasWrapper of ctasWrappers) {
                    if (ctasWrapper instanceof HTMLElement) {
                        ctasWrapper.classList.remove('init');
                    }
                }
            }, 1000);
        }
    };
};
