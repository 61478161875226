/* tslint:disable */
export const sanitizeLowerCase = (s:any) => {
    return s.toLowerCase();
};

export const sanitizeTrim = (s:any) => {
    return s.trim();
};

export const sanitize = (s:any) => {
    return s.toLoweCase().trim();
};
/* tslint:enable */

