/* tslint:disable */
require('./scss/main.scss');
document.addEventListener('touchstart', function () {}, false);
/* tslint:enable */

declare var __webpack_public_path__: string;
__webpack_public_path__ = _mgnl.contextPath + '/' + __webpack_public_path__;

import NavigationComponent from '@/components/NavigationComponent.vue';
import NavigationTabBar from '@/components/NavigationTabBar.vue';
import LanguageSwitcher from '@/components/LanguageSwitcher.vue';
import SearchBar from '@/components/SearchBar.vue';
import Cases from '@/components/Cases.vue';
import TextComponent from '@/components/TextComponent.vue';
import ContentPageSlider from '@/components/ContentPageSlider.vue';
import MediaSlider from '@/components/MediaSlider.vue';
import AnimatedButton from '@/components/AnimatedButton.vue';
import ProductFilter from '@/components/ProductFilter.vue';
import Newsletter from '@/components/Newsletter.vue';
import VForm from '@/components/VForm.vue';
import FormInputField from '@/components/FormInputField.vue';
import EmbeddedForm from '@/components/EmbeddedForm.vue';
import TablesController from '@/controllers/TablesController';
import ServicePointsMap from '@/components/ServicePointsMap.vue';
import RequestQuoteButton from '@/components/RequestQuoteButton.vue';
import FaqList from '@/components/FaqList.vue';
import SearchResults from '@/components/SearchResults.vue';
import AxleLoadProgram from '@/components/AxleLoadProgram.vue';
import RangeImageSlider from '@/components/RangeImageSlider.vue';

import { TweenMax, TimelineMax } from 'gsap';
import * as ScrollMagic from 'scrollmagic';
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';
ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

import { Preloader } from '@/components/Preloader';
import { AddToController, PrimaryCallToActionListener } from '@/components/CallToActions';

import {equalElementHeights} from '@/utils/equalElementHeights';
import {isMobile} from '@/utils/isMobile';

import Vue from 'vue';
import FeaturedContentCardsController from '@/controllers/FeaturedContentCardsController';

export * from './filters/translate';

Vue.config.productionTip = false;

const app = new Vue({
    comments: true,
    el: '#app',
    components: {
        NavigationComponent,
        NavigationTabBar,
        LanguageSwitcher,
        SearchBar,
        Cases,
        TextComponent,
        ContentPageSlider,
        MediaSlider,
        AnimatedButton,
        ProductFilter,
        Newsletter,
        VForm,
        RequestQuoteButton,
        FormInputField,
        EmbeddedForm,
        ServicePointsMap,
        FaqList,
        SearchResults,
        AxleLoadProgram,
        RangeImageSlider,
    },
    directives: {
    },
});



if (!Number.isNaN) {
    Object.defineProperty(Number, 'isNaN', {
        value: (value: any) => {
            return value !== value;
        },
    });
}

const header = document.querySelector('header');
const nav = document.querySelector('nav');
const cta1 = document.querySelectorAll('.cta1');
const cta2 = document.querySelectorAll('.cta2');
const images = document.querySelectorAll('.show-transparent-navigation');
const pageWrapper = document.querySelector('.wrapper');
let defaultHeroCTAButtonText: any;
let shortHeroCTAButtonText: any;
const heroCTABlock = document.querySelector('.header__cta');
const heroCTAButton = document.querySelector('.header__cta-button');
const breadcrumbs = document.querySelector('.breadcrumbs');
let heroCTAButtonText: any;

PrimaryCallToActionListener(cta1);

const controller = new ScrollMagic.Controller();

function setHeroCTALabel() {
    setTimeout(() => {
        if (heroCTAButton instanceof HTMLElement) {
            if (defaultHeroCTAButtonText && shortHeroCTAButtonText && isMobile) {
                const isShort = heroCTAButton.classList.contains('header__cta-button--sticky');
                heroCTAButtonText.innerHTML = isShort ? shortHeroCTAButtonText : defaultHeroCTAButtonText;
            }
            // const heroCTAButtonWidth = heroCTAButton.clientWidth;
            // heroCTAButton.style.width = heroCTAButtonWidth + 'px';
        }

    }, 20);
}

if (heroCTAButton instanceof HTMLElement) {
    const heroCTA = new ScrollMagic.Scene({
        offset: isMobile ? 300 : 80,
        triggerElement: '.header__cta-button',
        triggerHook: 'onLeave',
    })
        .setPin('.header__cta-button')
        .on('start', () => {
            setHeroCTALabel();
        })
        .setClassToggle('.header__cta-button', 'header__cta-button--sticky')
        .addTo(controller);
}

if (!isMobile) {
    if (heroCTABlock instanceof HTMLElement && heroCTAButton instanceof HTMLElement) {
        const buttonHeight = heroCTAButton.offsetHeight;
        heroCTABlock.style.bottom = -(buttonHeight) + 'px';

        if (breadcrumbs instanceof HTMLElement) {
            breadcrumbs.classList.add('breadcrumbs--padded');
        }

        shortHeroCTAButtonText = heroCTAButton.dataset.shortlabel;
        heroCTAButtonText = heroCTAButton.querySelector('span');
        if (heroCTAButtonText instanceof HTMLElement) {
            defaultHeroCTAButtonText = heroCTAButtonText.innerHTML;
        }
    }

    // TODO: Move to own script file
    if (nav) {
        if (images && images.length > 0) {
            for (const im of images) {
                if (im instanceof HTMLElement) {
                    const imHeight = im.offsetHeight;

                    new ScrollMagic.Scene({
                        triggerElement: im, duration: imHeight, triggerHook: 0.4,
                    })
                    .on('enter leave', (e) => {
                        if (e.type === 'enter') {
                            nav.classList.add('nav--invert-overrule');
                        } else {
                            nav.classList.remove('nav--invert-overrule');
                        }
                    })
                    .addTo(controller);
                }
            }
        }

        // Header
        if (header) {
            if (header.classList.contains('header--small')) {
                nav.classList.add('nav--invert');
            } else {
                new ScrollMagic.Scene({
                    triggerElement: header, triggerHook: 0, offset: header.offsetHeight / 2,
                })
                    .on('enter leave', (e) => {
                        if (e.type === 'enter') {
                            nav.classList.add('nav--invert');
                        } else {
                            nav.classList.remove('nav--invert');
                        }
                    })
                    // .addIndicators({name: "header"}) // add indicators (requires plugin)
                    .addTo(controller);
            }
        }
    }
    AddToController(cta1, cta2, controller);

} else {
    if (header instanceof HTMLElement) {
        const navBar = document.querySelector('.nav__tabbar');
        const navBarHeight = navBar instanceof HTMLElement ? navBar.offsetHeight : 0;
        const windowMaxInnerHeight = window.innerHeight - navBarHeight;

        // Set header height to max viewport height
        if (!header.classList.contains('header--small')) {
            header.style.height = windowMaxInnerHeight + 'px';
        }
    }
}


// Preloader
if (pageWrapper instanceof HTMLElement) {
    Preloader(pageWrapper);
}

equalElementHeights('.card-list--product, .card-list--related-products', '.card--product h3', 'dt');

const featuredContentCardsController = new FeaturedContentCardsController();
const pageTables = new TablesController();
